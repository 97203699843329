import React from 'react';
import dynamic from 'next/dynamic';

const Newsletter = dynamic(() =>
  import('@components/organisms/Newsletter/Newsletter').then((module) => module.Newsletter),
);

export default function El(element) {
  if (!element?.newsletters) return null;

  return <Newsletter newsletter={element?.newsletters} hasBackground />;
}
