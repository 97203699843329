import React from 'react';
import { PropsWithClassProps } from '@vgn-medien-holding/vgn-fe-components';
import { twMerge } from 'tailwind-merge';

export interface IconArrowProps extends PropsWithClassProps {
  filled?: boolean;
}

export const IconStar = ({ filled, classProps }: IconArrowProps) => {
  const svgStyle = twMerge('w-6 h-6 inline-block', classProps?.root);
  return (
    <svg className={svgStyle} viewBox="0 0 24 22" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 1.16101L15.0049 7.45831L15.1218 7.70323L15.3908 7.73869L22.3085 8.65057L17.248 13.4544L17.0512 13.6412L17.1006 13.9081L18.371 20.7689L12.2385 17.4406L12 17.3111L11.7615 17.4406L5.629 20.7689L6.89943 13.9081L6.94884 13.6412L6.75202 13.4544L1.69151 8.65057L8.60917 7.73869L8.87821 7.70323L8.99508 7.45831L12 1.16101Z"
        stroke="currentColor"
        fill={filled ? 'currentColor' : undefined}
      />
    </svg>
  );
};
