import React from 'react';
import dynamic from 'next/dynamic';
import { Link } from '@vgn-medien-holding/vgn-fe-components';
import { twMerge } from 'tailwind-merge';
import { Title } from '@components/atoms/Title';
import { IconCarretRight } from '@assets/icon-carret-right';

const Listicles = dynamic(() => import('@components/organisms/Listicles/Listicles').then((module) => module.Listicles));

export const El = (element, index) => {
  const hasLink = element.title_link || element.title_link !== '';
  return (
    <div className="relative pl-4 sm:pl-8 lg:pl-16">
      {element.title && (
        <Link
          noLink={!hasLink}
          href={element.title_link}
          classProps={{
            root: twMerge(
              'mb-3 flex select-none items-center font-herokid font-bold',
              hasLink && 'group cursor-pointer',
            ),
          }}
        >
          <Title
            level={2}
            classProps={{
              heading: 'relative text-xl lg:text-xl text-white leading-11 lg:leading-12',
            }}
          >
            {element.title}
          </Title>
          {hasLink && (
            <div className="relative ml-1 inline-grid h-full w-4 grid-cols-[1fr,auto] content-end items-center justify-end overflow-hidden whitespace-nowrap text-right text-2xs uppercase tracking-widest transition-all duration-300 group-hover:w-28 group-hover:text-primary">
              Alle sehen <IconCarretRight />
            </div>
          )}
        </Link>
      )}
      <Listicles
        listicle_type={element.listicle_type}
        limit={parseInt(element.limit)}
        genre_filter={element.genre_filter}
        items={element.items}
        query={element.query}
        dataSet={element.dataSet ?? []}
        onlyWithAsset={element.onlyWithAssets}
        onlyPrimetime={element.onlyPrimetime}
        showInitially={index < 3}
      />
    </div>
  );
};

export default El;
