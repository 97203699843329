import React from 'react';
import dynamic from 'next/dynamic';

const TeaserPromotion = dynamic(() =>
  import('@components/molecules/TeaserPromotion/TeaserPromotion').then((module) => module.TeaserPromotion),
);

export const El = (Element) => {
  if (!Element) return null;
  return <TeaserPromotion {...Element} />;
};

export default El;
