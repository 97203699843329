import React from 'react';
import { twMerge } from 'tailwind-merge';
import { Link } from '@components/atoms/Link/Link';
import { Title } from '@components/atoms/Title';
import { Listicles } from '@components/organisms/Listicles/Listicles';
import { IconCarretRight } from '@assets/icon-carret-right';

export const El = (element) => {
  if (!element?.lanes) return null;

  return (
    <div className="flex flex-col gap-24">
      {element?.lanes?.map((entry) => (
        <div key={entry?.title} className="relative pl-4 sm:pl-8 lg:pl-16">
          {entry.title && (
            <Link noLink={!entry.title_link} href={entry.title_link}>
              <div
                className={twMerge(
                  'relative z-10 mb-3 flex select-none items-center font-herokid font-bold',
                  entry.title_link && 'group cursor-pointer',
                )}
              >
                <Title
                  level={2}
                  classProps={{
                    heading: 'relative text-xl lg:text-xl text-white leading-11 lg:leading-12',
                  }}
                >
                  {entry.title}
                </Title>
                {entry.title_link && (
                  <div className="relative ml-1 inline-grid h-full w-4 grid-cols-[1fr,auto] content-end items-center justify-end overflow-hidden whitespace-nowrap text-right text-2xs uppercase tracking-widest transition-all duration-300 group-hover:w-28 group-hover:text-primary">
                    <div>Alle sehen</div>
                    <IconCarretRight />
                  </div>
                )}
              </div>
            </Link>
          )}
          <Listicles dataSet={[]} listicle_type={'MANUAL'} items={entry?.entries.map((entry) => entry.id)} query="" />
        </div>
      ))}
    </div>
  );
};

export default El;
