import React, { useEffect, useState } from 'react';

export interface CircleCounterProps {
  size: number;
  strokeWidth: number;
  duration: number;
  timeout?: number;
  active: boolean;
}

export const CircleCounter = ({ active, duration, timeout = 0, strokeWidth, size }: CircleCounterProps) => {
  const [isSleeping, setIsSleeping] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsSleeping(false);
    }, timeout);
    return () => clearTimeout(timer);
  }, [timeout]);

  let progress = 0;
  let style = {};

  if (active && !isSleeping) {
    style = {
      transition: `stroke-dasharray ${duration}ms linear`,
    };
    progress = 0;
    setTimeout(() => {
      progress = 100;
    }, 50);
  } else {
    progress = 100;
  }

  const radius = (size - strokeWidth) / 2;
  const circumference = radius * Math.PI * 2;
  const dash = (progress * circumference) / 100;

  return (
    <div className={`size-8 ${active && !isSleeping ? 'text-primary' : 'text-gray-400/50 dark:text-white/30'}`}>
      <svg viewBox={`0 0 ${size} ${size}`} width={size} height={size}>
        <circle
          className="origin-center"
          cx="50%"
          cy="50%"
          r={radius}
          strokeWidth={strokeWidth}
          stroke="currentColor"
          fill="none"
          transform="rotate(-90)"
          style={style}
          strokeDasharray={`${circumference - dash} ${dash}`}
        ></circle>
        <circle cx="50%" cy="50%" r={radius / 2.8} fill="currentColor"></circle>
        <circle
          cx="50%"
          cy="50%"
          r={radius}
          className={`fill-transparent stroke-gray-400/50 dark:stroke-white/30 ${active && !isSleeping && 'hidden'}`}
        ></circle>
      </svg>
    </div>
  );
};

<style></style>;
