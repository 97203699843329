import React from 'react';
import { Image } from '@vgn-medien-holding/vgn-fe-components';
import { twMerge } from 'tailwind-merge';
import { FallbackCard } from '@components/atoms/FallbackCard/FallbackCard';
import { Link } from '@components/atoms/Link/Link';
import { Title } from '@components/atoms/Title';
import { Carousel } from '@components/molecules/Carousel/Carousel';
import { IconCarretRight } from '@assets/icon-carret-right';

export const El = (element) => {
  if (!element?.customItems) return null;
  const hasLink = element.title_link || element.title_link !== '';
  return (
    <div className="relative pl-4 sm:pl-8 lg:pl-16">
      {element.title && (
        <Link noLink={!hasLink} href={element.title_link}>
          <div
            className={twMerge(
              'relative z-10 mb-3 flex select-none items-center font-herokid font-bold',
              hasLink && 'group cursor-pointer',
            )}
          >
            <Title
              level={2}
              classProps={{
                heading: 'relative text-xl lg:text-xl text-white leading-11 lg:leading-12',
              }}
            >
              {element.title}
            </Title>
            {hasLink && (
              <div className="relative ml-1 inline-grid h-full w-4 grid-cols-[1fr,auto] content-end items-center justify-end overflow-hidden whitespace-nowrap text-right text-2xs uppercase tracking-widest transition-all duration-300 group-hover:w-28 group-hover:text-primary">
                <div>Alle sehen</div>
                <IconCarretRight />
              </div>
            )}
          </div>
        </Link>
      )}
      <section className="space-y-32">
        <Carousel>
          {element?.customItems?.map((item) => (
            <Link
              href={item?.link}
              key={item.id}
              className="block w-min min-w-72 max-w-full rounded-lg border border-transparent transition-colors duration-300 hover:border-primary"
            >
              <div className="aspect-video w-full">
                <div className="relative size-full overflow-hidden rounded-lg rounded-b-none">
                  {item?.image?.file?.path ? (
                    <Image
                      src={item?.image.file.path}
                      alt={item?.image?.alt}
                      classProps={{ root: 'object-cover' }}
                      fill
                      sizes="480px"
                      copyright=""
                    />
                  ) : (
                    <FallbackCard />
                  )}
                </div>
              </div>
              <div className="mt-1 p-2">
                <div className="font-herokid font-medium">{item?.title}</div>
                <div className="label-sm mt-1">{item?.subheadline}</div>
              </div>
            </Link>
          ))}
        </Carousel>
      </section>
    </div>
  );
};

export default El;
