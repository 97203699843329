import React, { Fragment, useEffect, useState } from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { AditionTagWithFallback } from '@vgn-medien-holding/vgn-fe-components';
import { RESSORT_ARTICLES_PER_LOAD } from 'tvm-config';
import { AditionInitialization } from '@components/atoms/Adition/AditionInitialization';
import { Button } from '@components/atoms/Button/Button';
import { Meta } from '@components/atoms/MetaTags/Meta';
import { Oewa } from '@components/atoms/Oewa/Oewa';
import { ArticleOverviewHeader } from '@components/molecules/ArticleOverviewHeader/ArticleOverviewHeader';
import { ArticleTeaserCard } from '@components/organisms/ArticleTeaserCard/ArticleTeaserCard';
import { Article as ArticleLayout } from '@components/templates/layouts/Article';
import { GetAllArticlesPaginatedDocument } from '@src/lib/graphql/generated';
import { adPositions } from '@lib/adition/adPositions';
import { usePaginatedQuery } from '@lib/graphql/urql';

function Ressort({ data }) {
  const [activeRessort, setActiveRessort] = useState(data.activeRessort);
  const router = useRouter();

  useEffect(() => {
    const slug = router.asPath?.split('/')?.[1];
    const ressort = data?.ressorts.find((e) => e.slug === slug);

    if (!ressort) return;

    setActiveRessort(ressort);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.asPath]);

  const [
    { fetching: articlesLoading, data: articlesData, hasMorePages: hasMoreArticles, fetchNextPage: fetchMoreArticles },
  ] = usePaginatedQuery({
    query: GetAllArticlesPaginatedDocument,
    variables: {
      portal: 'tvmedia',
      ressort: activeRessort.id,
      first: RESSORT_ARTICLES_PER_LOAD,
      page: 1,
      orderBy: 'updated_at',
      orderDirection: 'DESC',
    },
  });
  const articlesPaginated = articlesData?.flatMap((data) => data?.articlesPaginated?.data);

  return (
    <>
      <AditionInitialization tags={adPositions} data={data} />
      <Oewa pageKey={'RedCont'} categoryKey={'KulturUndFreizeit'} profileKey={'FilmUndKino'} />
      <Head>{Meta(activeRessort.metadata, activeRessort.title)}</Head>

      <div className="px-4 py-5 pb-28 pt-10 lg:px-16">
        <ArticleOverviewHeader ressorts={data.ressorts} activeItem={activeRessort} />
        <div className={`relative grid gap-4 md:grid-cols-2 lg:grid-cols-fill-cards-default`}>
          {articlesPaginated?.map((article, idx) => (
            <Fragment key={idx}>
              {idx > 1 && idx % 6 === 0 && (
                <div className="grid place-items-center">
                  <AditionTagWithFallback
                    tag={adPositions[10]}
                    fallback={adPositions[20]}
                    breakpoint={'sm'}
                    tagCount={idx / 6 - 1}
                  />
                </div>
              )}
              <ArticleTeaserCard article={article} classProps={{ Image: 'aspect-movie' }} />
            </Fragment>
          ))}
        </div>
        <div className="grid justify-center py-4">
          {hasMoreArticles && (
            <Button
              title="Mehr laden"
              hasAction
              onAction={() => setTimeout(fetchMoreArticles)}
              isLoading={articlesLoading}
              buttonStyle="tertiary"
              classProps={{ button: 'border border-gray-650/25 mt-6' }}
            />
          )}
        </div>
      </div>
    </>
  );
}

Ressort.PageLayout = ArticleLayout;

export default Ressort;
