import React from 'react';
import dynamic from 'next/dynamic';
import { twMerge } from 'tailwind-merge';
import { Link } from '@components/atoms/Link/Link';
import { IconCarretRight } from '@assets/icon-carret-right';

const ArticleTeaserCard = dynamic(() =>
  import('@components/organisms/ArticleTeaserCard/ArticleTeaserCard').then((module) => module.ArticleTeaserCard),
);
const Carousel = dynamic(() => import('@components/molecules/Carousel/Carousel').then((module) => module.Carousel));

export default function El(element, showInitially) {
  const { articles } = element;
  const hasLink = !element.title_link || element.title_link === '';

  return (
    <div className="pl-4 sm:pl-8 lg:pl-16">
      {element.title && (
        <Link noLink={!hasLink} href={element.title_link}>
          <div
            className={twMerge(
              'relative z-10 mb-3 flex items-center font-herokid text-xl font-bold text-white',
              hasLink && 'group cursor-pointer',
            )}
          >
            {element.title}
            {hasLink && (
              <div className="relative ml-1 inline-grid h-full w-4 grid-cols-[1fr,auto] content-end items-center justify-end overflow-hidden whitespace-nowrap text-right text-2xs uppercase tracking-widest transition-all duration-300 group-hover:w-28 group-hover:text-primary">
                <div>Alle sehen</div>
                <IconCarretRight />
              </div>
            )}
          </div>
        </Link>
      )}
      {articles && (
        <Carousel classProps={{ slide: '!h-auto' }}>
          {articles.map((article, index) => (
            <ArticleTeaserCard
              key={index}
              article={article}
              hideCategory
              classProps={{
                root: 'w-80',
                ImageContainer: 'w-full h-full px-6 pt-6',
                Image: 'relative w-full h-full rounded-lg overflow-hidden aspect-teaser lg:aspect-video',
              }}
              showInitially={showInitially && index < 2}
            />
          ))}
        </Carousel>
      )}
    </div>
  );
}
