import { Logo } from '@components/atoms/Logo/Logo';

export const ToBeImplemented = ({ title }) => {
  return (
    <div className="mx-auto my-4 max-w-screen-md overflow-hidden px-8">
      <div className="inline-block rounded-t bg-gray-300 px-6 py-0.5">
        <Logo width={60} />
      </div>
      <div
        className={
          'grid min-h-[10em] content-center items-center gap-1 rounded-lg rounded-tl-none bg-gradient-to-b from-primary to-secondary p-4 text-white'
        }
      >
        <div className="font-herokid font-medium">To be implemented:</div>
        <div className="text-base uppercase tracking-widest">{title}</div>
      </div>
    </div>
  );
};
