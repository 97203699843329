import React from 'react';
import { twMerge } from 'tailwind-merge';
import { Link } from '@components/atoms/Link/Link';
import { Title } from '@components/atoms/Title';
import { Carousel } from '@components/molecules/Carousel/Carousel';
import { TvCurrentProgram } from '@components/molecules/TvCurrentProgram/TvCurrentProgram';
import { IconCarretRight } from '@assets/icon-carret-right';

export const El = (element) => {
  if (!element.topicEntries?.topics) return null;
  const hasLink = element.title_link || element.title_link !== '';
  return (
    <div className="relative pl-4 sm:pl-8 lg:pl-16">
      <section className="space-y-32">
        {element?.topicEntries?.topics.map((topic, index) => (
          <div key={topic.id}>
            {topic.title && (
              <Link noLink={!hasLink} href={topic.title_link}>
                <div
                  className={twMerge(
                    'relative z-10 mb-3 flex select-none items-center font-herokid font-bold',
                    hasLink && 'group cursor-pointer',
                  )}
                >
                  <Title
                    level={2}
                    classProps={{
                      heading: 'relative text-xl lg:text-xl text-white leading-11 lg:leading-12',
                    }}
                  >
                    {topic.title}
                  </Title>
                  {hasLink && (
                    <div className="relative ml-1 inline-grid h-full w-4 grid-cols-[1fr,auto] content-end items-center justify-end overflow-hidden whitespace-nowrap text-right text-2xs uppercase tracking-widest transition-all duration-300 group-hover:w-28 group-hover:text-primary">
                      <div>Alle sehen</div>
                      <IconCarretRight />
                    </div>
                  )}
                </div>
              </Link>
            )}
            <Carousel key={index} classProps={{ slide: '!h-auto' }}>
              {topic.showtimes.map((showtime) => (
                <TvCurrentProgram
                  classProps={{ root: 'block w-min min-w-72 max-w-full' }}
                  key={showtime.id}
                  primetime={false}
                  channelEntry={showtime}
                  channel={showtime.channel}
                  showDate={true}
                />
              ))}
            </Carousel>
          </div>
        ))}
      </section>
    </div>
  );
};

export default El;
