export const AboStickerOverview = ({ text, price }: { text: string; price: string }) => {
  if (!text) return null;
  const labelText = text?.split('{{price}}');

  return (
    <div className="absolute bottom-1/2 right-1/4 z-50 flex size-20 translate-y-1/2 flex-col items-center justify-center rounded-full bg-white text-center text-sm font-bold text-gray-800 shadow-card-sm lg:right-1/3">
      <div>{labelText?.[0]}</div>
      {labelText?.length > 1 && (
        <>
          <span className="font-herokid font-medium">{price}</span>
          <div>{labelText?.[1]}</div>
        </>
      )}
    </div>
  );
};
