import React from 'react';
import { PropsWithClassProps } from '@vgn-medien-holding/vgn-fe-components';
import { twMerge } from 'tailwind-merge';

export const IconCarretRight = ({ classProps }: PropsWithClassProps) => {
  const svgStyle = twMerge('w-4 h-4', classProps?.root);
  return (
    <svg className={svgStyle} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.0001 7.99994L10.3536 8.35349L10.7072 7.99994L10.3536 7.64638L10.0001 7.99994ZM6.35361 12.3535L10.3536 8.35349L9.64651 7.64638L5.64651 11.6464L6.35361 12.3535ZM10.3536 7.64638L6.35361 3.64639L5.64651 4.35349L9.64651 8.35349L10.3536 7.64638Z"
        fill="currentColor"
      />
    </svg>
  );
};
