import React from 'react';
import { AboutCard } from '@components/atoms/AboutCard/AboutCard';

export const El = (Element) => {
  const { special_type, sheets, bg_image } = Element;
  if (special_type === 'this_is_tvmedia') {
    return (
      <div className="px-4 sm:px-8 md:px-16 lg:px-32">
        <AboutCard cards={sheets} image={bg_image} />
      </div>
    );
  }
};

export default El;
