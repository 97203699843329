import React from 'react';
import { Title } from '@components/atoms/Title';
import { ToBeImplemented } from '@components/atoms/ToBeImplemented/ToBeImplemented';
import * as ContentElements from '@components/organisms/ContentElements/ContentElements';

export default function El(Element) {
  return (
    <div className="mx-auto w-full max-w-[1090px]">
      <PageElements content={Element?.content} />
    </div>
  );
}

function PageElements({ content }) {
  return (
    <div className="space-y-16 px-4 dark:text-white sm:px-8 md:px-16">
      {content.map((element, idx) => {
        return (
          <div key={idx}>
            {element?.headline && element?.headline_level && (
              <Title level={element.headline_level} classProps={{ heading: 'pb-4 dark:text-white lg:px-32' }}>
                {element.headline}
              </Title>
            )}
            {!ContentElements[element.__typename] ? (
              <ToBeImplemented title={element.__typename} />
            ) : (
              ContentElements[element.__typename](element)
            )}
          </div>
        );
      })}
    </div>
  );
}
