import React from 'react';
import dynamic from 'next/dynamic';

const Header = dynamic(() => import('@components/organisms/Header/Header').then((module) => module.Header));
const Footer = dynamic(() => import('@components/organisms/Footer/Footer').then((module) => module.Footer));

type Props = {
  children?: React.ReactNode;
  disableFooter?: boolean;
  disableTopSpacing?: boolean;
};

export const DefaultNoSpacing = ({ children, disableFooter, disableTopSpacing }: Props) => {
  return (
    <div className="dark relative h-screen w-full bg-black text-white">
      <Header classProps={{ root: 'relative' }} filled={false} disableTopSpacing={disableTopSpacing} />
      {/* INFO: -mt-px pt-px solves the issue with margin-top - please do not remove. :) */}
      <main className="-mt-px pt-px">
        <div className="w-full">{children}</div>
      </main>
      {!disableFooter && <Footer />}
    </div>
  );
};
