import { Heading, IconArrowCollapse, Image, PropsWithClassProps, Button } from '@vgn-medien-holding/vgn-fe-components';
import { twMerge } from 'tailwind-merge';
import { Button as TvmButton } from '@components/atoms/Button/Button';
import { SubscriptionOverviewOffer } from '@src/lib/graphql/generated';
import { AboStickerOverview } from '../AboSticker/AboStickerOverview';

export interface AboSelectionProps extends PropsWithClassProps<'info' | 'tagline' | 'title' | 'favorite'> {
  abo: SubscriptionOverviewOffer;
  onClick?: () => void;
  active?: boolean;
  small?: boolean;
}

export const AboSelection = ({ abo, small, active, onClick, classProps }: AboSelectionProps) => {
  if (!abo) return null;
  const { offer, tagline, title } = abo;

  return (
    <Button
      omit={!small}
      hasAction={true}
      classProps={{
        root: twMerge(
          'card-gradient w-full rounded-2xl border border-gray-650/65 p-6 text-start text-white shadow-card-base transition-all duration-200 *:transition-all *:duration-200 xl:p-4 mt-6',
          active && small && 'xl:border-primary',
          active && 'card-gradient',
          small && !active && 'hover:border-primary xl:bg-gray-820/70',
          classProps?.root,
        ),
      }}
      onAction={onClick}
    >
      {abo?.favorite && (
        <div
          className={twMerge(
            'mb-2 inline-block w-auto rounded bg-primary p-1 text-label-sm uppercase',
            classProps?.favorite,
          )}
        >
          Am Beliebtesten
        </div>
      )}
      <div className="flex items-center justify-between gap-4">
        <Heading
          level={3}
          classProps={{ root: twMerge('text-heading-4 font-herokid', classProps?.title) }}
          title={title || offer?.overview_title}
        />
        <IconArrowCollapse classProps={{ root: twMerge('rotate-0 xl:hidden', small && active && 'rotate-180') }} />
      </div>

      <div
        className={twMerge(
          'mr-9 mt-2 hidden text-sm text-gray-400',
          small && 'block',
          small && active && 'hidden xl:block',
          classProps?.tagline,
        )}
      >
        {abo?.tagline || offer?.overview_tagline}
      </div>
      <div className={twMerge('mt-6 hidden text-gray-400', active && 'block', small && 'xl:hidden', classProps?.info)}>
        {!!offer?.overview_image?.path && (
          <div className="relative mb-3 h-60">
            <Image
              alt={offer?.overview_image?.alt}
              copyright={offer?.overview_image?.copyright}
              src={offer?.overview_image?.path}
              fill
              sizes="50vw"
            />
            {!offer?.hide_breaker && (
              <AboStickerOverview price={offer?.overview_price_after} text={offer?.breaker_text} />
            )}
          </div>
        )}
        <div className="space-y-3">
          <div>
            {offer?.overview_price_previous && (
              <div className="text-base line-through lg:text-xl">&euro; {offer?.overview_price_previous}</div>
            )}
            <div
              className={twMerge(
                'flex items-center gap-3 text-white',
                (!offer.overview_price_textline_1 || !offer.overview_price_textline_2) && 'items-baseline',
              )}
            >
              <div className="whitespace-nowrap text-4xl font-bold lg:text-[2.5rem]">
                &euro; {offer?.overview_price_after}
              </div>
              <div className="text-sm lg:text-base">
                <div className="font-bold">{offer.overview_price_textline_1}</div>
                {offer.overview_price_textline_2}
              </div>
            </div>
          </div>
          <div className="text-lg font-semibold lg:text-xl lg:font-bold">{tagline ?? offer?.overview_tagline}</div>
          <div>{offer?.overview_subheadline}</div>
          <div dangerouslySetInnerHTML={{ __html: offer?.overview_benefits }} className="checkmark-list space-y-2.5" />
        </div>
        <TvmButton
          title="Jetzt Kaufen"
          hasAction={false}
          link={offer?.overview_buy_link}
          buttonStyle="primary"
          classProps={{ button: 'w-full !bg-primary hover:!bg-transparent justify-center my-10 !py-3 !px-10' }}
        />
        <div className="text-2xs leading-body prose-a:text-white prose-a:underline">
          Disclaimer: <span dangerouslySetInnerHTML={{ __html: offer?.top_disclaimer }} />
        </div>
      </div>
    </Button>
  );
};
