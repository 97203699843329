import React from 'react';
import { Image } from '@vgn-medien-holding/vgn-fe-components';
import { twMerge } from 'tailwind-merge';
import { Link } from '@components/atoms/Link/Link';
import { Title } from '@components/atoms/Title';
import { Carousel } from '@components/molecules/Carousel/Carousel';
import { IconCarretRight } from '@src/assets/icon-carret-right';
import type { PageContentElementTvmshorts } from '@src/lib/graphql/generated';

export default function Element(element: PageContentElementTvmshorts) {
  if (!element?.query_shorts?.shorts) return null;

  return (
    <div className="relative pl-4 sm:pl-8 lg:pl-16">
      {element?.title && (
        <Link href={element?.title_link}>
          <div className="group relative z-10 mb-3 flex cursor-pointer select-none items-center font-herokid font-bold">
            <Title
              level={2}
              classProps={{
                heading: 'relative text-xl lg:text-xl text-white leading-11 lg:leading-12',
              }}
            >
              {element?.title}
            </Title>
            <div className="relative ml-1 inline-grid h-full w-4 grid-cols-[1fr,auto] content-end items-center justify-end overflow-hidden whitespace-nowrap text-right text-2xs uppercase tracking-widest transition-all duration-300 group-hover:w-28 group-hover:text-primary">
              <div>Alle sehen</div>
              <IconCarretRight />
            </div>
          </div>
        </Link>
      )}
      <Carousel classProps={{ root: 'z-10' }}>
        {element?.query_shorts?.shorts?.map((short) => <Short key={short.id} short={short} />)}
      </Carousel>
    </div>
  );
}

function Short({ short }) {
  return (
    <Link
      href={`/shorts/${short.id}`}
      className={twMerge(
        'group relative z-10 mb-8 block aspect-short w-min min-w-40 shrink-0 pb-11 hover:z-40 md:mb-16',
      )}
    >
      <div className="absolute inset-0 aspect-short transition-all duration-150 group-hover:-m-4 group-hover:-mt-8 group-first/swiper-slide:group-hover:-mr-8 group-first/swiper-slide:group-hover:ml-0 group-last/swiper-slide:group-hover:-ml-8 group-last/swiper-slide:group-hover:mr-0">
        <Image
          src={short.thumbnail}
          alt={`Thumbnail von ${short.title}`}
          copyright=""
          fill
          sizes="192px"
          classProps={{ root: 'rounded-lg' }}
        />
        <div className="hyphenate line-clamp-3 w-full px-2 pt-2 font-herokid text-sm font-medium text-gray-900 group-hover:text-white dark:text-white md:pt-5 md:text-base">
          {short.title}
        </div>
      </div>
    </Link>
  );
}
