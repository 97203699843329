import { Image } from '@vgn-medien-holding/vgn-fe-components';
import { PropsWithClassProps } from '@vgn-medien-holding/vgn-fe-components';
import { twMerge } from 'tailwind-merge';
import { IconStar } from '@src/assets/icon-star';
import { SubscriptionPageFeeback } from '@src/lib/graphql/generated';

export const ReviewCard = ({
  name,
  position,
  text,
  stars,
  image,
  classProps,
}: SubscriptionPageFeeback & PropsWithClassProps) => {
  const staricons = Array.from({ length: stars ?? 5 }).map((_, index) => (
    <IconStar key={index} filled classProps={{ root: 'text-primary mr-1' }} />
  ));

  return (
    <div
      className={twMerge(
        'card-gradient max-w-[30rem] rounded-2xl border border-gray-650/65 px-4 py-6 text-white',
        classProps?.root,
      )}
    >
      {staricons}
      <div className="py-4 text-xl" dangerouslySetInnerHTML={{ __html: text }} />
      <div className="flex items-center">
        <div className="relative size-20 overflow-hidden rounded-full">
          {image && (
            <Image
              src={image.path}
              alt={image.alt || ''}
              copyright={image.copyright}
              fill
              imageMode="cover"
              className="rounded-full"
              sizes="160px"
            />
          )}
        </div>
        <div className="ml-2">
          <div className="text-base font-bold">{name}</div>
          <div className="text-label-sm uppercase text-gray-500">{position}</div>
        </div>
      </div>
    </div>
  );
};
