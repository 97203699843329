import React, { PropsWithChildren } from 'react';
import dynamic from 'next/dynamic';
import { twMerge } from 'tailwind-merge';
import { Logo } from '@components/atoms/Logo/Logo';

const Footer = dynamic(() => import('@components/organisms/FooterReduced/Footer').then((module) => module.Footer));

export const DefaultOnlyLogo = ({ children }: PropsWithChildren) => {
  return (
    <>
      <div className="dark relative h-screen w-full bg-black text-white">
        <header
          className={twMerge(
            'pointer-events-none fixed z-60 grid h-18 w-full content-center items-center gap-x-1.5 bg-gray-900/80 px-4 pb-1 text-white sm:gap-0 md:px-12 lg:h-25 lg:bg-transparent xl:px-16',
          )}
        >
          <Logo responsive link />
        </header>

        {/* INFO: -mt-px pt-px solves the issue with margin-top - please do not remove. :) */}
        <main className="-mt-px pt-px">
          <div className="w-full px-4 pt-26 sm:px-8 md:px-16 lg:mt-16">{children}</div>
        </main>
        <Footer />
      </div>
    </>
  );
};
