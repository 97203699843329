import { ArticleCategory } from '@components/atoms/ArticleCategory/ArticleCategory';
import { Ressort } from '@lib/graphql/generated';

export interface ArticleOverviewHeaderProps {
  activeItem: Ressort;
  ressorts: Ressort[];
}

export const ArticleOverviewHeader = ({ activeItem, ressorts }: ArticleOverviewHeaderProps) => {
  return (
    <div className="mb-12 grid items-start gap-4 lg:grid-cols-2">
      <div>
        <h1 className="text-sm uppercase tracking-widest text-gray-600">{activeItem.title}</h1>
        <div className="font-herokid text-2xl font-bold">{activeItem.subtitle}</div>
      </div>
      <div className="flex w-full flex-wrap gap-2 rounded-lg bg-white p-2 shadow-card-sm lg:ml-auto lg:w-auto">
        {ressorts &&
          ressorts.map((ressort) => {
            return (
              <ArticleCategory
                classProps={{ root: 'shadow-card-sm self-center' }}
                active={ressort.id === activeItem.id}
                category={ressort}
                isSwitcher
                key={ressort.id}
              />
            );
          })}
      </div>
    </div>
  );
};
